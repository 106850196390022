// Generated by Framer (cfa3f9f)

import { addFonts, cx, CycleVariantState, getFonts, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import Carousel from "https://framerusercontent.com/modules/UIrMjSS6ZX89L0CsT8k6/l0YNWo5RXtJCEhIkKpL5/Carousel.js";
const CarouselFonts = getFonts(Carousel);

const cycleOrder = ["EHFHpeTjB"];

const variantClassNames = {EHFHpeTjB: "framer-v-1x128xr"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "EHFHpeTjB", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "EHFHpeTjB", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-WBtWp", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1x128xr", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"EHFHpeTjB"} ref={ref} style={{...style}} transition={transition}><motion.div className={"framer-5bnidl-container"} data-framer-name={"Mobile Carousel (All)"} layoutDependency={layoutDependency} layoutId={"j3rXbDPYy-container"} name={"Mobile Carousel (All)"} transition={transition}><Carousel align={"center"} ariaLabel={""} arrowObject={{showMouseControls: false, arrowFill: "rgba(0, 0, 0, 0.2)", arrowSize: 40, arrowRadius: 40, arrowPadding: 20}} axis borderRadius={0} fadeObject={{fadeAlpha: 0, fadeContent: false, fadeInset: 0, fadeTransition: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}, fadeWidth: 25}} gap={40} height={"100%"} id={"j3rXbDPYy"} layoutId={"j3rXbDPYy"} name={"Mobile Carousel (All)"} padding={10} paddingBottom={0} paddingLeft={0} paddingPerSide={false} paddingRight={0} paddingTop={0} progressObject={{dotsActiveOpacity: 1, dotsBackground: "rgba(0, 0, 0, 0.2)", dotsBlur: 4, dotsFill: "rgb(255, 255, 255)", dotsGap: 10, dotsInset: 10, dotSize: 10, dotsOpacity: 0.5, dotsPadding: 10, dotsRadius: 50, showProgressDots: false, showScrollbar: false}} sizingObject={{heightInset: 0, heightRows: 2, heightType: "auto", widthColumns: 2, widthInset: 0, widthType: "auto"}} slots={[]} snapObject={{fluid: false, snap: true, snapEdge: "center"}} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-WBtWp [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-WBtWp * { box-sizing: border-box; }", ".framer-WBtWp .framer-ldsjk0 { display: block; }", ".framer-WBtWp .framer-1x128xr { height: 98px; overflow: visible; position: relative; width: 400px; }", ".framer-WBtWp .framer-5bnidl-container { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 98
 * @framerIntrinsicWidth 400
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const FramerkP5VVexxm: React.ComponentType<Props> = withCSS(Component, css, "framer-WBtWp") as typeof Component;
export default FramerkP5VVexxm;

FramerkP5VVexxm.displayName = "Tabs Caroussel";

FramerkP5VVexxm.defaultProps = {height: 98, width: 400};

addFonts(FramerkP5VVexxm, [...CarouselFonts])